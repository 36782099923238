import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [];

export const dictionary = {
		"/(public)": [6,[3]],
		"/(public)/auth-actions": [7,[3]],
		"/(public)/check-inbox": [8,[3]],
		"/(public)/checkout": [9,[3]],
		"/(authenticated)/dashboard": [4,[2]],
		"/(authenticated)/dashboard/files": [5,[2]],
		"/(public)/login": [10,[3]],
		"/(public)/reset-password": [11,[3]],
		"/(public)/signup": [12,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';